import React, { useState } from "react";
import styled from "styled-components/macro";
import errorImage from "../assets/images/error.png";
import downArrowIcon from "../assets/images/calenderIcon.png";
import { useSelector } from "react-redux";
import { Field } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const InputBorder = styled.div`
  border: ${(props) =>
    props.error
      ? "1px solid red"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #D7DAE0"};
  border-radius: 10px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  background-color: ${(props) =>
    props.themeColor ? "transparent" : "#ffffff"};
  margin-top: ${(props) => (props.error ? "0.7rem" : "0")};
  position: relative;

  img {
    height: 18px;
    width: 18px;
  }

  input {
    font: 1.1rem;
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  }

  .react-datepicker-wrapper {
    width: 100% !important;
    display: flex !important;
  }

  .react-datepicker {
    background-color: ${(props) =>
      props.themeColor ? "#0c111d" : "#fff"} !important;
    /* background-color: #ffffff !important; */
    border: none !important;

    box-shadow: ${(props) =>
      props.themeColor
        ? "8px 3px 22px 10px #0000004d"
        : "8px 3px 22px 10px #9696961c"} !important;
    padding: 0.8rem 1rem !important;
    border-radius: 16px !important;
  }

  .react-datepicker__header {
    background-color: ${(props) =>
      props.themeColor ? "#0c111d" : "#fff"} !important;
    /* background-color: #ffffff !important; */
    border-bottom: none !important;
  }
  .react-datepicker__navigation {
    margin-top: 0.7rem !important;
    &::before {
      content: none;
    }
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    display: none !important;
  }

  .react-datepicker__current-month {
    /* border-bottom: 1px solid #d7dae0 !important; */
    margin-bottom: 5px !important;
    padding-bottom: 6px !important;
    color: ${({ themeColor }) =>
      themeColor ? "#CECFD2" : "#0d2344"} !important;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: 400 !important;
  }

  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${({ themeColor }) =>
      themeColor ? "#CECFD2" : "#0d2344"} !important;
    text-transform: uppercase !important;
    margin: 5px !important;
  }
  .react-datepicker__day-name {
    color: ${({ themeColor }) =>
      themeColor ? "#85888E" : "#0d2344"} !important;
    text-transform: uppercase !important;
    margin: 5px !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
    /* color: #ffffff !important; // White text */
    border-radius: 50% !important;
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected:hover {
    border-radius: 50% !important;
    background-color: #f05d24 !important; // Darker blue on hover
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today,
  .react-datepicker__year-text--today {
    background-color: transparent !important; /* No background color */
    border: 2px solid #f05d24 !important; /* Orange circle border */
    border-radius: 50% !important;
  }
  .react-datepicker__day--disabled {
    /* color: #d3d3d3 !important; Grey color for disabled dates */
    color: ${({ themeColor }) =>
      themeColor ? "#85888E" : "#d3d3d3"} !important;
    pointer-events: none;
    /* background-color: #f9f9f9 !important; Light grey background for disabled dates */
    background-color: ${(props) =>
      props.themeColor ? "#0c111d" : "#fff"} !important;
  }

  /* Optional: Custom styling to highlight disabled dates in red */
  .react-datepicker__day--disabled:hover {
    background-color: #ffcccc !important; /* Light red background on hover for disabled dates */
  }
  .react-datepicker__day:hover {
    border-radius: 0.3rem;
    /* background-color: #f0f0f0; */
    background-color: ${(props) => (props.themeColor ? "#F05D24" : "#f0f0f0")};
  }

  .custom-selected-day {
  background-color: #f05d24 !important;
  color: #ffffff !important;
  border-radius: 50% !important;
}
`;
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.7rem;
`;
export const InputLable = styled.label`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.3rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  margin-bottom: 0.5rem;
  text-transform: capitalize;

  @media (max-width: 351px) {
    font-size: 0.8rem;
  }
`;
export const InBox = styled.div`
  display: flex;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8A94A6")};
  font-size: 0.8rem;
  align-items: center;
  width: 7%;
  /* z-index: -2; */

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-left: 4px;
  }
`;
export const CustomDatePicker = styled(DatePicker)`
  border: none;
  outline: none;
  padding: 6px 5px;
  width: 100%;
  background-color: transparent !important;
  color: ${(props) => (props.themeColor ? "#FFFFFF" : "#161b26")};
  /* background-color: ${(props) =>
    props.themeColor ? "#161b26 !important" : "#ffffff !important"}; */
`;
export default function YearInput(props) {
  const {
    error,
    type,
    icon,
    noBorderBottom,
    label,
    star,
    optional,
    feildLoading,
    cursorNot,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const theme = useSelector((state) => state?.isDark);
  const [open, setOpen] = useState(false);

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  const getDayClassName = (date, selectedDate) => {
    const isSelectedDay =
      selectedDate &&
      date.getDate() === selectedDate.getDate() &&
      date.getMonth() === selectedDate.getMonth() &&
      date.getFullYear() === selectedDate.getFullYear();
    return isSelectedDay ? "custom-selected-day" : "";
  };
  return (
    <>
      <InputBox>
        {feildLoading ? (
          <Skeleton
            width={80}
            height={18}
            style={{ marginBottom: "5px" }}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        ) : (
          <InputLable themeColor={theme}>
            {label}
            {star ? <span style={{ color: "#F6591C" }}>*</span> : ""}
            {optional ? (
              <span style={{ color: "#667085" }}> (Optional)</span>
            ) : (
              ""
            )}
          </InputLable>
        )}
        {feildLoading ? (
          <Skeleton
            width={"100%"}
            height={40}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
            style={{ marginBottom: "5px" }}
          />
        ) : (
          <InputBorder
            error={error}
            themeColor={theme}
            style={{
              cursor: cursorNot ? "not-allowed" : "",
              // opacity: cursorNot ? "0.4" : "1",
              backgroundColor: theme
                ? cursorNot
                  ? "#161B26"
                  : "#0C111D"
                : cursorNot
                ? "#F6F7F9"
                : "#ffffff",
            }}
          >
            <CustomDatePicker
              themeColor={theme}
              dateFormat="dd/MM/yyyy"
              style={{ textAlign: "right", width: "100%", cursor: cursorNot ? "not-allowed" : "", }}
              onCalendarOpen={() => setOpen(true)}
              onClickOutside={() => setOpen(false)}
              onCalendarClose={() => setOpen(false)}
              autoComplete="off"
              className="date-pico"
              dayClassName={(date) => getDayClassName(date, props.selected)}
              open={open}
              {...props}
              disabled={cursorNot}
            />
            <InBox themeColor={theme} onClick={() => {
              if(!cursorNot) {
                setOpen(!open)
              }
            }}>
              <img src={downArrowIcon} alt="" />
            </InBox>

            {/* {error ? <img src={errorImage} alt="" /> : ""} */}
          </InputBorder>
        )}

        {error ? (
          <p
            style={{
              paddingTop: 5,
              fontSize: 11,
              color: "red",
            }}
          >
            {error}
          </p>
        ) : null}
      </InputBox>
    </>
  );
}
