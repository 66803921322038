import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";

export const ManageLearnerBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  margin-top: ${fontProvider.two};
`;
export const ManageCertiRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  `
  export const ComContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const ComRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.9rem;

  p {
    font-size: 0.8rem;
    font-weight: 500;
    color: ${(props) => (props.themeColor ? "#94969C" : "#363A44")};
  }
`;
export const ManageHead = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ManageComm = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;
export const ManageCommPolicy = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    justify-content: space-between;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;
export const ManageCommResp = styled.div`
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    justify-content: space-between;
    margin-top: 0.6rem;
  }
  @media (max-width: 425px) {
    width: 50% !important;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  min-width: 270px;
  /* background-color: #ffffff; */
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 10px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  input {
    border: none;
    outline: none;
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#85888E" : "#8A94A6")};
    border: none;
    outline: none;
    text-transform: capitalize;
  }

  img {
    height: 17px;
    width: 17px;
    margin-right: 0.5rem;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    width: 100%;
  }
`;
export const SearchBoxReport = styled.div`
  display: flex;
  min-width: 270px;
  /* background-color: #ffffff; */
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 10px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  input {
    border: none;
    outline: none;
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#85888E" : "#8A94A6")};
    border: none;
    outline: none;
    text-transform: capitalize;
  }

  img {
    height: 17px;
    width: 17px;
    margin-right: 0.5rem;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    width: 100%;
  }
  @media (min-width: 769px) {
    display: none;
  }
`;
export const SearchBoxPolicy = styled.div`
  display: flex;
  min-width: 270px;
  /* background-color: #ffffff; */
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 10px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  input {
    border: none;
    outline: none;
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#85888E" : "#8A94A6")};
    border: none;
    outline: none;
    text-transform: capitalize;
  }

  img {
    height: 17px;
    width: 17px;
    margin-right: 0.5rem;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    min-width: unset;
  }
`;
export const ComIcon = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  background-color: ${(props) =>
    props.themeColor ? "transparent" : colorProvider.navBackground};
  border-radius: ${commonStyles.nav_br};
  padding: ${fontProvider.zeroEig};
  cursor: ${commonStyles.pointer};

  img {
    height: 20px;
    width: 20px;
    object-fit: ${commonStyles.ob_con};
  }

  i {
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
  }
`;
export const AddCommonButton = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: ${fontProvider.zeroEig} ${fontProvider.oneThree};
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 10px;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
  @media (max-width: 425px) {
    width: 100%;
    justify-content: center;
  }
`;
export const AddCommonButtonPolicy = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: ${fontProvider.zeroEig} ${fontProvider.oneThree};
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 10px;
  }

  @media (max-width: 769px) {
    span {
      display: none;
    }
  }
  @media (max-width: 425px) {
    justify-content: center;
  }
`;
export const ProgressRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto; */
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  padding: ${fontProvider.zeroTen};
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  margin-top: ${fontProvider.oneSev};
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
`;
export const ProgressFirstRow = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  /* @media (max-width: 375px) {
    flex-direction: column;
  } */
`;
export const ComProgress = styled.div`
  width: 50%;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  border-right: 1px solid #d7dae0;

  /* @media (max-width: 320px) {
    width: 100%;
    border-right: none;
  } */
  @media (max-width: 768px) {
    &:nth-child(2) {
      border-right: none;
    }
  }

  .com-container {
    display: ${commonStyles.ds_fx};
    flex-direction: ${commonStyles.fd_col};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_fs};
  }

  .head-text {
    font-size: ${fontProvider.oneFive};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
    text-align: ${commonStyles.jc_l};
    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
  .desc-text {
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
    text-align: ${commonStyles.jc_l};
  }
`;
export const TotalCountRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  padding: 1rem;

  /* @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }*/

  /* @media (max-width: 375px) {
    justify-content: flex-start;
    gap: 10px;
  } */

  .com-container {
    width: 50%;
    display: ${commonStyles.ds_fx};
    justify-content: ${commonStyles.jc_c};
    align-items: ${commonStyles.jc_c};
    border-right: 1px solid #d7dae0;
    /* @media (max-width: 480px) {
      width: 100%;
      border-right: none;
    } */
    /* @media (max-width: 768px) {
      width: 100%;
      border-right: none;
    } */
    @media (max-width: 768px) {
      flex-direction: column;
      :nth-child(2) {
        border-right: none;
      }
    }
  }

  .head-text {
    font-size: ${fontProvider.zeroNin};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
    text-align: ${commonStyles.jc_l};
    margin-left: 5px;
    @media (max-width: 375px) {
      font-size: 0.8rem;
    }
  }
  .desc-text {
    font-size: ${fontProvider.zeroSev};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
    text-align: ${commonStyles.jc_l};
    @media (max-width: 375px) {
      font-size: 0.6rem;
    }
  }
`;
export const TotalCountRowResp = styled.div`
  display: flex;
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const FormContainerLearner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 8px;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #edeef1"};

  .draggable-item {
    transition: transform 0.3s ease;
  }
  @media (max-width: 1100px) {
    padding: 0.7rem;
  }
  @media (max-width: 520px) {
    padding: 0.4rem;
  }
`;
export const CheckBoxs = styled.div`
  cursor: pointer;
  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.ceNter ? "center" : "end")};
  border-top: ${(props) =>
    props.isBorder
      ? props.themeColor
        ? "1px solid #333741"
        : "1px solid #d7dae0"
      : "1px solid transparent"};
  padding-top: ${(props) => (props.isBorder ? "1rem" : "0")};
  margin-top: ${(props) => (props.isBorder ? "0.8rem" : "0")};
  align-items: center;
`;
export const CircleBox = styled.div`
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const CircleText = styled.p`
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.four};
  color: ${(props) =>
    props.themeColor ? colorProvider.whiteThree : colorProvider.numberT};
  margin-left: 7px;
`;
export const DropContainerResp = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: row;
  align-items: ${commonStyles.ai_c};
  @media (max-width: 768px) {
    display: none;
  }
`;
export const DropContainerRespTwo = styled.div`
  display: none;
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 0.8rem;
  }
`;
export const DropContainer = styled.div`
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DownloadButton = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${fontProvider.zeroEig} ${fontProvider.oneThree};
  cursor: pointer;
  width: 100%;

  img {
    height: 18px !important;
    width: 18px !important;
    object-fit: contain;
    margin-right: 10px;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
