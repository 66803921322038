import React, { useEffect, useState } from "react";
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";
import styled from "styled-components/macro";
import axios from "axios";

export const CertiContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F7F9;
  /* transform: scale(0.1); */
`;
export const InnerCertiContainer = styled.div`
  width: 100%;
  height: 600px;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #edeef1; */
  position: relative;
`;
export const BackContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: -1;

  img {
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
  /* #certificate-container {
    width: 210mm; // A4 width in portrait in millimeters
    height: 297mm; // A4 height in portrait in millimeters
    padding: 20mm;
  } */
`;
export const HmContainer = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
`;
export const HmInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-color: #edeef1;
  border-style: solid;
  padding: 2.5rem;
  border-width: 2px 0px 2px 2px; // top, right, bottom, left
`;
export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const RainLogo = styled.div`
  /* width: 30%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    height: 32px !important;
    width: 100% !important;
    object-fit: contain;
  }
`;
export const RightLogo = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    height: 32px;
    /* width: 100%; */
    object-fit: contain;
  }
`;
export const CertificateTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  p {
    font-family: EB Garamond;
    color: #f05d24;
    font-size: 2.9rem;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
  }
`;
export const CertificateClarify = styled.div`
  width: 40%;
  display: flex;
  margin: 1rem 0;

  p {
    font-family: EB Garamond;
    color: #000000;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
export const CertificateName = styled.div`
  width: 60%;
  display: flex;
  border-bottom: 1px solid #0d2344;
  padding-bottom: 0.4rem;
  margin-top: 0.5rem;

  p {
    color: #0d2344;
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    font-style: italic;
  }
`;
export const CertificateDesc = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1.1rem;

  p {
    font-family: EB Garamond;
    color: #000000;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 25px;
  }
`;
export const DateStamp = styled.div`
  width: 100%;
  display: flex;
  margin-top: 1rem;

  p {
    font-family: EB Garamond;
    color: #000000;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 1px;
  }
`;
export const RightDesignContainer = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

export const SignatureRowLearner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0rem;
  margin-top: 1rem;
  margin-left: -1rem;
`;
export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const SignatureBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 40px;
    width: 120px;
    object-fit: contain;
  }

  .underline-border {
    height: 1px;
    width: 80px;
    background-color: #363a44;
    margin-top: 2px;
  }
`;
export const TypeLable = styled.div`
  margin: 0.3rem 0;
  p {
    font-size: 0.8rem;
    color: #000000;
    font-weight: 500;
    text-transform: capitalize;
  }
`;
export const TypeSubLable = styled.div`
  p {
    font-size: 0.6rem;
    color: #000000;
    font-weight: 400;
    text-transform: capitalize;
  }
`;
const NoSignCertificate1 = ({ headerRightLogo, anotony, clientName }) => {
//   const downloadPdfDocument = async () => {
//     const input = document.getElementById("certificate-container");
//     const canvas = await html2canvas(input);
//     const imgData = canvas.toDataURL("image/jpeg");

//     // Initialize jsPDF
//     const pdf = new jsPDF({
//       orientation: "landscape",
//       unit: "mm",
//       format: "a4",
//     });

//     // Add image to PDF
//     const imgProps = pdf.getImageProperties(imgData);
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
//     pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);

//     // Save the PDF
//     pdf.save("certificate.pdf");
//   };

  return (
    <>
      <CertiContainer>
        <InnerCertiContainer id="certificate-container">
          <BackContainer>
            <img
              src="https://s3rain.s3.ap-south-1.amazonaws.com/1717996420665_backImage.png"
              alt=""
            />
          </BackContainer>
          <HmContainer>
            <HmInnerContainer>
              <FirstRow>
                <RainLogo>
                  <img
                    src="https://s3rain.s3.ap-south-1.amazonaws.com/1717996285387_rainlogo.png"
                    alt=""
                  />
                </RainLogo>
                {headerRightLogo ? (
                  <RightLogo>
                    <img
                      src="https://s3rain.s3.ap-south-1.amazonaws.com/1725363455800_logoImage.png"
                      alt=""
                    />
                  </RightLogo>
                ) : (
                  ""
                )}
              </FirstRow>
              <CertificateTitle
                style={{ marginTop: "1.2rem", marginBottom: "-10px" }}
              >
                <p>Certificate</p>
              </CertificateTitle>
              <CertificateTitle style={{}}>
                <p>of Completion</p>
              </CertificateTitle>
              <CertificateClarify>
                <p>This is to certify that</p>
              </CertificateClarify>
              <CertificateName>
                <p>(Your Name)</p>
              </CertificateName>
              <CertificateDesc>
                <p>
                  of Rainmaker Online Training Solutions has completed the One
                  Day Workshop on Internal Committee Capacity and Skill Building
                  (Primer & Investigations) under the Sexual Harassment of Women
                  at Workplace (Prevention, Prohibition and Redressal) Act,
                  2013, and Rules.
                </p>
              </CertificateDesc>
              <DateStamp>
                <p>Date : DD-MM-YYYY</p>
              </DateStamp>
              <SignatureRowLearner>
                {anotony ? (
                  <SignatureContainer>
                    <SignatureBox>
                      <img
                        src="https://s3rain.s3.ap-south-1.amazonaws.com/1717996328913_signature.png"
                        alt=""
                      />
                      <div className="underline-border"></div>
                    </SignatureBox>
                    <TypeLable>
                      <p>Anthony</p>
                    </TypeLable>
                    <TypeSubLable>
                      <p>CEO (Rainmaker)</p>
                    </TypeSubLable>
                  </SignatureContainer>
                ) : (
                  ""
                )}
                {clientName ? (
                  <SignatureContainer>
                    <SignatureBox>
                      <img
                        src="https://s3rain.s3.ap-south-1.amazonaws.com/1717996328913_signature.png"
                        alt=""
                      />
                      <div className="underline-border"></div>
                    </SignatureBox>
                    <TypeLable>
                      <p>Client Name</p>
                    </TypeLable>
                    <TypeSubLable>
                      <p>CEO (Company Name)</p>
                    </TypeSubLable>
                  </SignatureContainer>
                ) : (
                  ""
                )}
              </SignatureRowLearner>
            </HmInnerContainer>
          </HmContainer>
          <RightDesignContainer>
            <img
              src="https://s3rain.s3.ap-south-1.amazonaws.com/1717996469308_frame.png"
              alt=""
            />
          </RightDesignContainer>
        </InnerCertiContainer>
      </CertiContainer>
      {/* <button onClick={downloadPdfDocument}>Download Certificate</button> */}
    </>
  );
};

export default NoSignCertificate1;
