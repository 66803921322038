import styled from "styled-components/macro";
import {
  commonStyles,
  colorProvider,
  fontProvider,
  fontWeight,
} from "../utils/StylePropertiy";
import checkboxIcon from "../assets/images/Checkbox.png";
import checkboxBaseIcon from "../assets/images/_Checkbox_base.png";
import darkCheckboxBaseIcon from "../assets/images/dark_base.png";
import darkcheckIcon from "../assets/images/darkcheckIcon.png";
import checkbox_minus_light from "../assets/images/checkbox_minus_light.png";
import checkbox_minus_dark from "../assets/images/checkbox_minus_dark.png";

export const ComMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const TrailerRow = styled.div`
  width: ${commonStyles.wid};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  visibility: visible;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  position: absolute;
  /* margin-top: 5rem; */
  right: 0;
  /* transform: translateX(-50%); */
  z-index: 10;
  right: -50px;
  z-index: 10;
  top: 30px;
  transition: all 0.5s ease;
`;
export const MainSection = styled.section`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c}; */
  flex-direction: ${commonStyles.fd_col};
  margin-top: 1rem;
  position: relative;

  .horizontal-scroll {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    align-items: center;
    padding: 2rem 0px;
  }

  .cards-container {
    display: flex;
    width: 660px;
  }

  .card {
    flex: 0 0 420px;
    margin: 0 10px;
    /* background-color: #f0f0f0; */
    /* padding: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: ${commonStyles.ds_fx};
    flex-direction: ${commonStyles.fd_col};
    justify-content: ${commonStyles.jc_fs};
    align-items: ${commonStyles.jc_fs};
    box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
    border: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.blackFive}`
        : commonStyles.course_bor};
    border-radius: ${commonStyles.sec_br};
    transition: transform 0.3s linear;
    :hover {
      transform: scale(1.1);
    }
    &:hover ${TrailerRow} {
      opacity: 1;
      visibility: visible;
    }
  }

  .card-image {
    width: 430px;
    height: 240px;
    object-fit: cover;
    display: block;
  }

  .horizontal-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
`;
export const MainSectionCaetgory = styled.section`
  width: ${commonStyles.wid};
  max-width: 1350px;
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c}; */
  flex-direction: ${commonStyles.fd_col};
  margin-top: 1rem;
  position: relative;

  .horizontal-scroll {
    overflow: auto;
    white-space: nowrap;
    width: 100%;
    align-items: center;
    padding: 2rem 0px;
    position: absolute;
    left: -40px;
    padding-left: 2.4rem;
    margin-top: 2.9rem;
  }

  .cards-container {
    display: flex;
    width: 660px;
  }

  .card {
    flex: 0 0 420px;
    margin: 0 10px;
    /* background-color: #f0f0f0; */
    /* padding: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: ${commonStyles.ds_fx};
    flex-direction: ${commonStyles.fd_col};
    justify-content: ${commonStyles.jc_fs};
    align-items: ${commonStyles.jc_fs};
    box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
    border: ${(props) =>
      props.themeColor
        ? `1px solid ${colorProvider.blackFive}`
        : commonStyles.course_bor};
    border-radius: ${commonStyles.sec_br};
    transition: transform 0.3s linear;
    :hover {
      transform: scale(1.1);
    }
    &:hover ${TrailerRow} {
      opacity: 1;
      visibility: visible;
    }
  }

  .card-image {
    width: 430px;
    height: 240px;
    object-fit: cover;
    display: block;
  }

  .horizontal-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
`;
export const InnerSectionSuccessTwo = styled.div`
  width: 100%;
  text-align: center;

  .otp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 80%;
    align-items: center;
    margin: auto;
    margin-bottom: 1rem;
  }
  .verifyDiv {
    padding: 18px;
  }
  p {
    color: white;
    margin-bottom: 0px;
  }
  .inputStyle {
    width: 60px !important;
    height: 60px;
    border-radius: 7px;
    border: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: #dddddd;
    font-size: 2rem;
    outline: none;
    color: #363a44;
    font-weight: 600;
  }
  .inputStyle-error {
    width: 60px !important;
    height: 60px;
    border-radius: 7px;
    border: 1px solid red;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 2rem;
    outline: none;
    color: red;
    font-weight: 600;
  }
  .inputStyle-success {
    width: 50% !important;
    height: 60px;
    border-radius: 7px;
    border: 1px solid #17b26a;
    margin-left: 8px;
    margin-right: 8px;
    background: transparent;
    font-size: 2rem;
    outline: none;
    color: #17b26a;
    font-weight: 600;
  }
  .p3 {
    font-size: 14px;
  }
  .resend {
    text-decoration: underline;
    font-size: 14px;
  }
  .otpElements {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 18px;
    justify-content: center;
  }

  .p1 {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    margin-top: 25px;
    font-size: 27px;
  }

  .p2 {
    font-family: "Poppins", sans-serif;
    font-size: 13px;
  }
`;
export const InnerSection = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  /* justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c}; */
  flex-direction: ${commonStyles.fd_col};
  @media (max-width: 320px) {
    padding: 0 1rem;
  }
  @media (max-width: 480px) {
    padding: 0 1rem;
  }
  @media (max-width: 769px) {
    padding: 0 1rem;
  }
  @media (max-width: 1024px) {
    padding: 0 1rem;
  }
`;

export const RequestBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  background-color: ${colorProvider.blueColor};
  border-radius: ${commonStyles.sec_br};
  padding: 1.4rem 1rem;
`;
export const ReqFirst = styled.div`
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  font-size: ${fontProvider.zeroEig};

  span {
    font-weight: ${fontWeight.four};
    color: ${colorProvider.lightGrey};
  }
`;
export const ButtonGroup = styled.div`
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  gap: 0.8rem;
`;
export const SubmitButton = styled.button`
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  border-radius: ${commonStyles.sec_br};
  color: ${colorProvider.white};
  cursor: pointer;
  font-size: ${fontProvider.zeroSev};
  background-color: ${(props) =>
    props.backColor ? props.backColor : colorProvider.orange};
`;
export const NameDescBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.ai_fs};
  flex-direction: ${commonStyles.fd_col};
  margin-top: 1rem;
`;
export const TitleSuccess = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
  font-weight: 600;
  margin-bottom: 1rem;
`;
export const Title = styled.div`
  width: ${commonStyles.wid};
  font-family: EB Garamond;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: ${fontWeight.five};
  color: ${colorProvider.blackOne};
  color: ${(props) =>
    props.themeColor ? colorProvider.navBackground : colorProvider.blackOne};
  text-align: ${commonStyles.jc_l};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TitleNormal = styled.div`
  width: ${commonStyles.wid};
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: ${fontWeight.five};
  color: ${colorProvider.blackOne};
  color: ${(props) =>
    props.themeColor ? colorProvider.navBackground : colorProvider.blackOne};
  text-align: ${commonStyles.jc_l};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TitleHome = styled.div`
  width: ${commonStyles.wid};
  font-family: EB Garamond;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: ${fontWeight.five};
  color: ${colorProvider.blackOne};
  color: ${(props) =>
    props.themeColor ? colorProvider.navBackground : colorProvider.blackOne};
  text-align: ${commonStyles.jc_l};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const TitleTwo = styled.div`
  width: ${commonStyles.wid};
  /* font-family: EB Garamond; */
  font-size: 1.3rem;
  font-weight: ${fontWeight.five};
  color: ${colorProvider.blackOne};
  color: ${(props) =>
    props.themeColor ? colorProvider.navBackground : colorProvider.blackOne};
  text-align: ${commonStyles.jc_c};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
`;
export const TitleSo = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
  font-weight: 600;
  margin-bottom: 1rem;
`;
export const MomoTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
  font-weight: 600;
  margin-bottom: 1rem;
`;
export const DescText = styled.div`
  width: ${commonStyles.wid};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.four};
  color: ${colorProvider.comLink};
  text-align: ${commonStyles.jc_l};
`;
export const CardComContainer = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: relative;
`;
export const BorderTitle = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  align-items: ${commonStyles.ai_c};
  justify-content: ${commonStyles.jc_sb};
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: 0.8rem;
  margin-top: 2rem;
  position: relative;

  .font-style {
    font-family: EB Garamond;
  }
  p {
    font-size: ${fontProvider.zeroTen};
    font-weight: ${fontWeight.six};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyFour : colorProvider.blackCol};
  }
  .view-all {
    font-size: ${fontProvider.zeroEig};
    font-weight: ${fontWeight.five};
    color: ${(props) =>
      props.themeColor ? colorProvider.greyThree : colorProvider.greyThree};
  }
`;
export const MediaCardsBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.ai_c};
  margin-top: 2rem;
  gap: 1.5rem;
  cursor: pointer;
`;
export const MediaCard = styled.div`
  width: 30%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: relative;
  border-radius: ${commonStyles.sec_br};
`;
export const CardImage = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-radius: ${commonStyles.sec_br};

  img {
    height: ${commonStyles.het};
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
    border-radius: ${commonStyles.sec_br};
  }
`;
export const CardContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: absolute;
  bottom: 0;
`;
export const InnerContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  background-color: ${colorProvider.transColor};
  border-radius: 0 0 ${commonStyles.sec_br} ${commonStyles.sec_br};
  padding: 1rem;
  backdrop-filter: blur(20px);
`;
export const CartTitleRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
`;
export const CartTitle = styled.div`
  font-size: ${fontProvider.zeroTen};
  font-weight: ${fontWeight.four};
  color: ${colorProvider.white};
  font-family: EB Garamond;

  span {
    font-family: EB Garamond;
    font-weight: ${fontWeight.eight};
  }
`;
export const CartLeftTime = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
`;
export const CartDescRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  align-items: ${commonStyles.ai_c};
  margin-top: 5px;
`;
export const CartDescription = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.five};
  color: ${colorProvider.greyTer};
`;
export const CartDeadLine = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.warOrange};
`;
export const CourseCardsBox = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.ai_c};
  margin-top: 2rem;
  gap: 1.5rem;
  margin-bottom: 2rem;
`;
export const CourseCard = styled.div`
  width: 95%;
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  position: relative;
  border-radius: ${commonStyles.sec_br};
`;
export const CourseImage = styled.div`
  height: ${commonStyles.het};
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.ai_c};
  border-radius: ${commonStyles.sec_br} ${commonStyles.sec_br} 0 0;

  img {
    height: ${commonStyles.het};
    width: ${commonStyles.wid};
    object-fit: ${commonStyles.ob_con};
    border-radius: ${commonStyles.sec_br} ${commonStyles.sec_br} 0 0;
  }
`;
export const CourseContent = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  padding: 0.8rem;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.darkBlue : colorProvider.white};
`;
export const CourseContentTwo = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  padding: 0 0;
  background-color: ${(props) =>
    props.themeColor ? "#1F242F" : colorProvider.white};
`;
export const ContentRow = styled.div`
  width: ${commonStyles.wid};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_sb};
  margin-bottom: 14px;
  align-items: ${commonStyles.jc_c};
  @media (max-width: 320px) {
    flex-direction: column;
    gap: 1rem;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 1rem;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
export const ComContentRow = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  cursor: ${commonStyles.pointer};
  align-items: ${commonStyles.jc_sb};
  width: 100%;
`;
export const ComContentRowTwo = styled.div`
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  cursor: ${commonStyles.pointer};
  align-items: ${commonStyles.jc_sb};
  width: 100%;
`;
export const CourseTitle = styled.div`
  width: 100%;
  font-size: ${fontProvider.oneTwo};
  font-weight: ${fontWeight.seven};
  color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blueColor};
  font-family: EB Garamond;
  margin-bottom: 4px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-family: EB Garamond;
    font-weight: ${fontWeight.eight};
  }
`;
export const CourseTitleTwo = styled.div`
  width: 100%;
  font-size: ${fontProvider.oneTwo};
  font-weight: ${fontWeight.seven};
  color: #f05d24;
  font-family: EB Garamond;
  margin-bottom: 4px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-family: EB Garamond;
    font-weight: ${fontWeight.eight};
  }
`;
export const CourseSub = styled.div`
  font-size: ${fontProvider.zeroSev};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CourseDescription = styled.div`
  width: 100%;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const CourseDescriptionTwo = styled.div`
  width: 100%;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  display: block;
  overflow: hidden; // Prevents content from spilling over
  -webkit-line-clamp: 2; // Limits the content to two lines
  -webkit-box-orient: vertical; // Sets the direction of content flow to vertical
  text-overflow: ellipsis; // Adds an ellipsis when the text overflows
  max-height: 34px; // Double the line-height to ensure only two lines are displayed
  opacity: 0;
  margin-left: 20px;
  margin-top: 20px;
  transition: 0.5s;
  height: 0;
`;
export const CourseDescriptionThree = styled.div`
  width: 100%;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) => (props.themeColor ? "#ECECED" : "#464C5E")};
  text-transform: capitalize;
  white-space: wrap;
  line-height: 17px;
  max-width: 700px;
  overflow: hidden; // Prevents content from spilling over
  text-overflow: ellipsis; // Adds an ellipsis when the text overflows
  max-height: 34px; // Double the line-height to ensure only two lines are displayed
`;
export const CourseDescriptionTwoDis = styled.div`
  width: 100%;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.greyThree : colorProvider.comLink};
  text-transform: capitalize;
  line-height: 17px;
  display: flex;
  overflow: hidden; // Prevents content from spilling over
  -webkit-line-clamp: 2; // Limits the content to two lines
  -webkit-box-orient: vertical; // Sets the direction of content flow to vertical
  text-overflow: ellipsis; // Adds an ellipsis when the text overflows
  max-height: 34px; // Double the line-height to ensure only two lines are displayed
`;
export const CourseDescriptionTwoDisSo = styled.div`
  width: 400px;
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.five};
  color: ${(props) =>
    props.themeColor ? colorProvider.whiteTwo : colorProvider.numberT};
  text-transform: capitalize;
  line-height: 17px;
  display: flex;
  overflow: hidden; // Prevents content from spilling over
  -webkit-line-clamp: 2; // Limits the content to two lines
  -webkit-box-orient: vertical; // Sets the direction of content flow to vertical
  text-overflow: ellipsis; // Adds an ellipsis when the text overflows
  white-space: nowrap;
  max-height: 34px; // Double the line-height to ensure only two lines are displayed
`;
export const ViewDetails = styled.div`
  font-size: ${fontProvider.zeroNin};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.orange};
  text-transform: capitalize;
  margin-top: 7px;
`;
export const ComContainerRenew = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
export const RenewButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colorProvider.orange};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  text-align: ${commonStyles.jc_c};
  padding: ${fontProvider.zeroFive} ${fontProvider.zeroTen};
  @media (max-width: 320px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;
// export const TrailerRow = styled.div`
//   width: ${commonStyles.wid};
//   display: flex;
//   /* visibility: hidden;
//   transition: opacity 0.3s ease-in-out; */
//   justify-content: ${commonStyles.jc_fe};
//   align-items: ${commonStyles.jc_c};
// `;

export const TrailerButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colorProvider.orange};
  font-size: ${fontProvider.zeroEig};
  font-weight: ${fontWeight.six};
  color: ${colorProvider.white};
  border-radius: ${commonStyles.sec_br};
  text-align: ${commonStyles.jc_c};
  padding: ${fontProvider.zeroFive} ${fontProvider.zeroTen};
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  align-items: ${commonStyles.jc_c};
  gap: 0.5rem;
  padding: 0.6rem 1rem;
  margin-top: 7rem;
  margin-right: 1rem;

  img {
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
  }
  @media (max-width: 320px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const SectionContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
`;
export const InnerContainer = styled.div`
  /* height: 100%; */
  min-height: 720px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#f6f7f9")};
  padding: 0 1rem;

  @media (max-width: 510px) {
    padding: 0 0.7rem;
  }
`;
export const BannerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: 1rem;

  @media (max-width: 769px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const TitleBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  img {
    height: 13px;
    width: 13px;
    margin-right: 1rem;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const TitleBoxTwo = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;

  img {
    height: 13px;
    width: 13px;
    margin-right: 1rem;
    object-fit: contain;
    cursor: pointer;
  }
`;
export const HeadTitle = styled.div`
  font-size: 1.6rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 500;
  font-family: "EB Garamond", serif;
  text-transform: capitalize;
`;
export const HeadDesc = styled.div`
  font-size: 0.7rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  margin-top: 3px;
  text-transform: capitalize;
`;
export const AddCommonButton = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 10px;
  }

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export const AddCommonButtons = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: none;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      display: none;
    }
    img {
      margin: 0;
    }
  }
`;
export const AddCommonButtonLearner = styled.div`
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: #f6591c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
    margin-right: 5px;
  }

  @media (max-width: 769px) {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 0;
    }
  }
`;
export const ParentTable = styled.div`
  width: 100%;
  min-height: 700px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 769px) {
    margin-bottom: 6rem;
  }
`;
export const TableBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 1rem; */
  text-transform: capitalize;

  ::-webkit-scrollbar {
    width: 6px !important;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    /* Color of the thumb */
    border-radius: 5px !important;
    /* Rounded corners for the thumb */
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 21px !important;
    width: 21px !important;

    /* background: ${(props) =>
      props.themeColor
        ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
        : `url(${checkboxBaseIcon}) no-repeat center center !important`}; */
    background-size: contain !important;
    /* background-color: transparent !important; */
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #B3B9C6"} !important;
    border-radius: 6px;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "" : "21px !important")};
    width: ${(props) => (props.checkCon ? "" : "21px !important")};
    background-color: transparent !important;
    background: ${(props) =>
      props.checkCon
        ? ""
        : props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "22px !important" : "")};
    width: ${(props) => (props.checkCon ? "22px !important" : "")};
    margin-left: 6.2px !important;
    margin-top: 0.8px !important;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.checkCon
        ? props.themeColor
          ? `url(${darkcheckIcon}) no-repeat center center !important`
          : `url(${checkboxIcon}) no-repeat center center !important`
        : ""};
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  .border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  /* update */
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox
    .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  /* @media (max-width: 769px) {
    display: none;
  } */
  &&& :where(.css-p7e5j5).ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners for the thumb */
  }
  /* @media (max-width: 769px) {
    display: none;
  } */
`;
export const TableBoxPolicy = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 1rem; */
  text-transform: capitalize;

  ::-webkit-scrollbar {
    width: 6px !important;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    /* Color of the thumb */
    border-radius: 5px !important;
    /* Rounded corners for the thumb */
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 21px !important;
    width: 21px !important;

    /* background: ${(props) =>
      props.themeColor
        ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
        : `url(${checkboxBaseIcon}) no-repeat center center !important`}; */
    background-size: contain !important;
    /* background-color: transparent !important; */
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #B3B9C6"} !important;
    border-radius: 6px;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "" : "21px !important")};
    width: ${(props) => (props.checkCon ? "" : "21px !important")};
    background-color: transparent !important;
    background: ${(props) =>
      props.checkCon
        ? ""
        : props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "22px !important" : "")};
    width: ${(props) => (props.checkCon ? "22px !important" : "")};
    margin-left: 6.2px !important;
    margin-top: 0.8px !important;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.checkCon
        ? props.themeColor
          ? `url(${darkcheckIcon}) no-repeat center center !important`
          : `url(${checkboxIcon}) no-repeat center center !important`
        : ""};
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  .border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  /* update */
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox
    .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  /* @media (max-width: 769px) {
    display: none;
  } */
  &&& :where(.css-p7e5j5).ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners for the thumb */
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const TableCard = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1rem 1.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  @media (max-width: 769px) {
    display: none;
  }
  @media (max-width: 520px) {
    padding: 1rem;
  }
`;
export const TableCardPolicy = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1rem 1.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  @media (max-width: 769px) {
    display: flex;
  }
  @media (max-width: 520px) {
    padding: 1rem;
  }
`;

export const TableTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
`;
export const TableTitleRowDusra = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 1rem;
`;
export const ComShowButton = styled.div`
  background-color: ${(props) =>
    props.backColor
      ? props.themeColor
        ? "#333741"
        : props.backColor
      : "#363a44"};
  color: ${(props) =>
    props.colorText
      ? props.themeColor
        ? "#94969C"
        : props.colorText
      : "#363a44"};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid transparent"};
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 10px;
  margin-right: 1rem;
  text-transform: capitalize;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  @media (max-width: 463px) {
    font-size: 0.8rem;
  }
`;
export const CheckRowBox = styled.div`
  width: 5%;
  display: flex;
  justify-content: left;
  align-items: flex-start;

  @media (max-width: 488px) {
    width: 10%;
  }
`;
export const TitleRowFirst = styled.div`
  width: ${(props) => (props.wiDth ? props.wiDth : "90%")};
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  @media (max-width: 488px) {
    width: 100% !important;
  }
  @media (max-width: 346px) {
    width: 100% !important;
  }
`;
export const RowBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
export const DocuemntTitle = styled.div`
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
`;
export const OtherPolicyTitle = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: capitalize;
  text-align: left;
  color: ${(props) => (props.themeColor ? "#94969C" : "#3D424F")};
`;
export const ComContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: -3rem;
`;
export const TitleRowInnerFirst = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
export const BoxesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const FirstRowBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
  justify-content: space-between;
`;
export const ViewMoreBtn = styled.div`
font-size:0.8rem;
text-align: center;
width: 100%;
color:#F6591C;
`;
export const TitleRowInnerSecond = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
`;
export const TitleRowFirstTitle = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363a44")};
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
  @media (max-width: 375px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TitleRowFirstDesc = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#464C5E")};
  text-transform: capitalize;
  margin-top: 0.6rem;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.9rem;
  }
`;
export const TitleRowFirstDescGrey = styled.div`
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#8A94A6")};
  text-transform: capitalize;
  margin-top: 0.6rem;
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 463px) {
    font-size: 0.8rem;
  }
`;
export const TitleRowFirstDate = styled.div`
  width: 100%;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8a94a6")};
  margin-top: 5px;
`;
export const TitleRowFirstDateBlack = styled.div`
  width: 100%;
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#464C5E")};
  margin-top: 5px;
  @media (max-width: 463px) {
    font-size: 0.75rem;
  }
`;
export const TitleRowFirstDates = styled.div`
  width: 100%;
  font-size: 1.1rem;
  font-weight: 400;
  color: ${(props) => (props.themeColor ? "#94969C" : "#8a94a6")};
  margin-top: 10px;
  @media (max-width: 463px) {
    font-size: 0.8rem;
  }
`;
export const TitleRowSecond = styled.div`
  width: 10%;
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  position: relative;
  z-index: 1;

  .dot-button {
    img {
      height: 20px;
    }
  }
`;
export const Message = styled.div`
  color: white;
  padding: 1rem;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  height: ${(props) => (props.isHeight ? props.isHeight : "80px")};
  width: 100px;
  position: absolute;
  top: 0;
  margin-top: 2.1rem;
  margin-right: -1rem;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  display: ${(props) => (props.dropdownOpen ? "flex" : "none")};
  flex-direction: column;

  &:after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${(props) =>
      props.theme === "mine" ? "#FFFFFF" : "#FFFFFF"};

    border-width: 10px;
    margin-left: 65px;
  }
`;
export const MenuRow = styled.li`
  width: 100%;
  display: flex;
  font-size: 0.8rem;
  color: #363a44;
  align-items: center;
  font-weight: 500;

  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
`;
export const TableContentBox = styled.div`
  width: 90%;
  flex-wrap: wrap;
  color: ${(props) => (props.themeColor ? "#94969C" : "#464c5e")};
  font-weight: 400;
  font-size: 0.8rem;
  margin-top: 1rem;
  line-height: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines after which text will be truncated */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6em; /* Adjust based on your requirements */
  height: 3.2em; /* Height is 2 times the line-height */
  word-wrap: break-word;
`;
export const SearchFilterRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
  position: relative;
`;
export const SearchFilterRowClient = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;

  @media (max-width: 769px) {
    flex-direction: column;
  }
`;
export const SearchFilterRowLearner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
  padding: 0 1.5rem;

  @media (max-width: 769px) {
    padding: 0;
    margin: 0;
  }
`;
export const SearchBox = styled.div`
  display: flex;
  /* background-color: #ffffff; */
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #D7DAE0"};
  border-radius: 8px;
  padding: 10px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};

  input {
    border: none;
    outline: none;
    background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
  input::placeholder {
    font-size: 0.8rem !important;
    color: ${(props) => (props.themeColor ? "#85888E" : "#8A94A6")};
    border: none;
    outline: none;
    text-transform: capitalize;
  }

  img {
    height: 17px;
    width: 17px;
    margin-right: 0.5rem;
    object-fit: contain;
  }

  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  align-items: center;
  border: ${(props) =>
    props.themeColor
      ? "1px solid #333741"
      : props.isFilterOpen
      ? "1px solid #FBA976"
      : "1px solid #d7dae0"};
  box-shadow: ${(props) =>
    props.isFilterOpen ? "0px 0px 0px 2px #FEE8D6" : ""};
  border-radius: 8px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#464c5e")};
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;

  .text-display {
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    width: 10%;
    justify-content: center;
    align-items: center;
    /* padding: 0; */
    .text-display {
      display: none;
    }
    img {
      margin: 0;
    }
  }
`;

export const ZohoBox = styled.div`
  display: flex;
  background-color: transparent;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-size: 0.8rem;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
  }
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    width: 100%;
    justify-content: right;
    align-items: center;
    img {
      /* margin: 0; */
    }
  }
`;
export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`;
export const InnerOverlayContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: end;
  /* align-items: center; */
`;
export const BlurBox = styled.div`
  height: 100%;
  width: 50%;
  background-color: black;
  opacity: 0.5;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const OverlayBox = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#ffffff")};
  /* align-items: center; */
  padding: 2rem 1rem;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const OverlayCrosRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

  @media (max-width: 769px) {
    justify-content: flex-start;
  }
`;
export const CrossButton = styled.div`
  cursor: pointer;
  img {
    height: 13px;
    width: 13px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const CrossButtons = styled.div`
  cursor: pointer;
  display: none;
  img {
    height: 13px;
    width: 13px;
    object-fit: contain;
  }
  @media (max-width: 769px) {
    margin-bottom: 10px;
    display: flex;
  }
`;
export const ProfileRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-bottom: 1rem; */
`;
export const ProfileBox = styled.div`
  width: 12%;
  display: flex;
  justify-content: left;
  align-items: center;
`;
export const ProfileImage = styled.div`
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 0.5px solid lightgray;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
`;
export const ProfileContentBox = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 8px;
`;
export const ProfileTitle = styled.div`
  width: 100%;
  font-size: 1.2rem;
  color: ${(props) => (props.themeColor ? "#FAFAFA" : "#24262d")};
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;
export const ProfileRowBox = styled.div`
  width: 90%;
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
    margin-top: 0.4rem;
    span {
      display: non;
    }
  }
  /* justify-content: left; */
  /* align-items: center; */
`;
export const ProfileDescRow = styled.div`
  width: 100%;
  font-size: 0.8rem;
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
  }

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
`;
export const ProfileDescRowCon = styled.div`
  /* display: flex; */
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;

  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  @media (max-width: 769px) {
    width: 50%;
    margin-top: 0.2rem;
  }
`;
export const CommonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
`;
export const ProfileSubTitle = styled.div`
  width: 100%;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
`;
export const ProfileSubDesc = styled.div`
  width: 100%;
  font-size: 0.9rem;
  color: ${(props) => (props.themeColor ? "#ECECED" : "#667085")};
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: 20px;
`;
export const MediaRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741;" : "1px solid #d7dae0"};
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 10px;
`;
export const MediaBox = styled.div`
  img {
    height: 40px;
    width: 40px;
    object-fit: contain;
  }
`;
export const MediaContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const MediaTitle = styled.div`
  width: 100%;
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};

  @media (max-width: 411px) {
    width: 150px;
  }
`;
export const MediaDescBox = styled.div`
  width: 100%;
  display: flex;
`;
export const MediaDescText = styled.div`
  font-size: 0.7rem;
  font-weight: 400;
  text-align: left;
  color: #8a94a6;
  display: flex;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #8a94a6;
  font-weight: 500;

  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-left: 10px;
  }
`;
// export const CourseTitle = styled.div`
//   width: 100%;
//   font-size: 1rem;
//   font-weight: 600;
//   border-bottom: ${(props) =>
//     props.themeColor ? "1px solid transparent" : "1px solid #d7dae0"};
//   padding-bottom: 10px;
//   color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
// `;
export const VideoRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${(props) =>
    props.themeColor ? "1px solid #333741;" : "1px solid #d7dae0"};
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 10px;
`;
export const FileRow = styled.div`
  width: 100%;
  display: flex;
`;
export const VideoImageBox = styled.div`
  img {
    height: 70px;
    width: 100%;
    object-fit: contain;
  }
`;
export const VideoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* justify-content: space-between; */
  margin-left: 8px;
`;
export const VideoTitleRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
export const VideoTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    border: none;
    outline: none;
    background: transparent;
  }
  img {
    height: 10px;
    width: 10px;
    object-fit: contain;
  }
`;
export const MinBox = styled.div`
  font-size: 0.8rem;
  color: #667085;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#667085")};
  width: 100%;
  margin: 7px 0;
`;
export const TagsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const TagsTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363A44")};
  width: 100%;
  margin-bottom: 10px;
`;
export const TagsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const ComTags = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#161B26" : "#f6f7f9")};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid transparent"};
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  font-size: 0.8rem;
  padding: 5px 7px;
  font-weight: 500;
  margin-right: 7px;
  border-radius: 4px;
`;
export const DescContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.8rem;
  color: #667085;
  font-weight: 400;
  line-height: 17px;
`;
export const FeedbackRow = styled.div`
  width: 100%;
  display: flex;
  border: ${(props) =>
    props.activeColor
      ? "1px solid #FBA976"
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #edeef1"};
  border-radius: 8px;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  @media (max-width: 424px) {
    flex-direction: column;
  }
`;
export const ComFeedbackBox = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  @media (max-width: 640px) {
    width: 25%;
    margin-top: 1rem;
  }
  @media (max-width: 511px) {
    width: 30%;
  }
  @media (max-width: 424px) {
    width: 100%;
  }
`;
export const ComFeedbackRow = styled.div`
  /* width: 16.6%; */
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;
export const FeedbackTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-size: 0.7rem;
  font-weight: 500;
`;
export const FeedbackOrangeText = styled.div`
  color: #f6591c;
  /* color: ${(props) => (props.activeColor ? "#f6591c" : "#667085")}; */
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
`;
export const FeedbackText = styled.div`
  color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
  /* color: ${(props) => (props.activeColor ? "#f6591c" : "#667085")}; */
  font-size: 0.8rem;
  font-weight: 500;
  display: flex;
`;
export const CheckBox = styled.div`
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-left: 10px;
  }
`;
export const CheckBoxs = styled.div`
  cursor: pointer;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    margin-right: 10px;
  }
`;
export const FeedbackDesc = styled.div`
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#363a44")};
  font-weight: 500;
  font-size: 0.8rem;
  margin-top: 5px;
`;
// export const InnerSection = styled.div`
//   width: 100%;
//   text-align: center;
// `;
export const InnerSections = styled.div`
  width: 100%;
  text-align: left;
`;
export const InnerSectionsBanner = styled.div`
  width: 100%;
  min-width: 600px;
  text-align: left;
  @media (max-width: 768px) {
    min-width: unset;
  }
`;
export const InnerSectionBanner = styled.div`
  width: 100%;
  text-align: left;
  padding: 1rem 0.5rem;
  min-width: 500px;

  @media (max-width: 622px) {
    width: 100%;
  }
`;

export const MainTitle = styled.div`
  width: 100%;
  font-size: 1.5rem;
  color: #000;
`;

// export const Title = styled.div`
//   width: 100%;
//   font-size: 1.2rem;
//   color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262D")};
//   font-weight: 600;
//   margin-bottom: 1rem;
// `;
export const TitleDesc = styled.div`
  width: 100%;
  font-size: 0.9rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-weight: 400;
  margin-bottom: 0.5rem;
`;

export const Subtitle = styled.div`
  width: 100%;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#667085")};
  font-weight: 400;
  line-height: 25px;
`;
export const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
export const BtnClose = styled.button`
  width: 100%;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  background-color: ${(props) =>
    props.themeColor ? "#0C111D" : "transparent"};
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#000000")};
  box-shadow: ${(props) =>
    props.themeColor ? "0px 1px 2px 0px #1018280D" : ""};
  border: ${(props) =>
    props.themeColor ? "1px solid #333741" : "1px solid #b3b9c6"};
  padding: 1.6rem 0;
  line-height: 0;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
`;
export const ComButton = styled.button`
  width: 50%;
  background-color: ${(props) =>
    props.color
      ? props.backColor
      : props.themeColor
      ? "#0C111D"
      : "transparent"};
  color: ${(props) =>
    props.color ? props.color : props.themeColor ? "#CECFD2" : "#3D424F"};
  border: ${(props) =>
    props.color
      ? props.themeColor
        ? "1px solid transparent"
        : `1px solid ${props.borderColor}`
      : props.themeColor
      ? "1px solid #333741"
      : "1px solid #B3B9C6"};
  padding: 1.4rem 0;
  line-height: 0;
  box-shadow: ${(props) =>
    props.themeColor ? "0px 1px 2px 0px #1018280D" : ""};
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
`;
export const WarnIconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
// Delete Box

export const DeleteContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  border-radius: 14px 14px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  position: fixed;
  bottom: 0;
  left: 0;

  @media (max-width: 769px) {
    display: flex;
  }
  @media (max-width: 601px) {
    padding: 0.5rem 0;
  }
`;
export const DeleteComButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#24262d")};
  font-weight: 400;
  margin-right: 3rem;

  img {
    height: 22px;
    width: 22px;
    object-fit: contain;
    margin-bottom: 0.6rem;
  }
`;
export const RespBox = styled.div`
  display: none;
  @media (max-width: 769px) {
    display: flex;
  }
`;
// Name Temp
export const LangBox = styled.div`
  display: flex;
  align-items: center;
`;
export const LangCircle = styled.div`
  /* height: 15px;
  width: 15px; */
  text-align: center;
  background-color: #edeef1;
  color: #f05d24;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: -10px;
  border: 1.5px solid #ffffff;
`;
export const LangPlusCon = styled.div`
  color: #464c5e;
  font-weight: 400;
  font-size: 1rem;
`;
export const CrossRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;

  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }
`;
export const PagiButton = styled.div`
  min-height: 2rem;
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  left: 0;
  margin-left: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
    @media (max-width: 768px) {
      display: none;
    }
  }
  img {
    height: 12px;
    width: 12px;
    object-fit: contain;
  }
`;
export const PagiButtonNext = styled.div`
  min-height: 2rem;
  border: 1px solid #d7dae0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  right: 0;
  margin-right: 1rem;
  border: ${(props) =>
    props.themeColor ? "1px solid #334155" : "1px solid #D7DAE0"};
  background-color: ${(props) =>
    props.themeColor ? "#161B26" : "transparent"};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  p {
    font-size: 0.8rem;
    color: ${(props) => (props.themeColor ? "#94969C" : "#667085")};
    font-weight: 500;
    @media (max-width: 768px) {
      display: none;
    }
  }
  img {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }
`;

// Filter Options
export const FilterOptions = styled.div`
  width: 25%;
  display: flex;
  /* display: ${(props) => (props.isOpen ? "flex" : "none")}; */
  flex-direction: column;
  justify-content: left;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  right: 0;
  top: 0;
  margin-top: 2.5rem;
  /* margin-right: 1rem; */
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: 1px solid #edeef1;
  border-radius: 8px;
  z-index: 9999;
`;
export const FilterHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #edeef1;

  p {
    font-weight: 600;
    font-size: 1rem;
    color: #24262d;
  }

  img {
    height: 10px;
    width: 10px;
    cursor: pointer;
    object-fit: contain;
  }
`;
export const ComTitle = styled.div`
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  font-weight: 500;
`;

export const MediaIconBox = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${(props) => (props.themeColor ? "#F5F5F6" : "#363A44")};
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 5px;
  cursor: pointer;

  img {
    height: 18px;
    width: 18px;
    object-fit: contain;
    margin: 0 !important;
  }
`;
// Image
export const ImgContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
`;
// export const AddCommonButton = styled.div`
//   color: #ffffff;
//   text-transform: capitalize;
//   font-size: 0.8rem;
//   font-weight: 600;
//   background-color: #f6591c;
//   border-radius: 8px;
//   display: flex;
//   align-items: center;
//   padding: 8px 12px;
//   cursor: pointer;

//   img {
//     height: 12px;
//     width: 12px;
//     object-fit: contain;
//     margin-right: 5px;
//   }

//   @media (max-width: 769px) {
//     margin-top: 1rem;
//   }
// `;
export const SuccessIconBoxThree = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
export const SuccessIconBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  img {
    height: 200px;
    width: 200px;
    object-fit: contain;
  }
`;
export const SuccessIconBoxTwo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0.8rem 0;

  img {
    height: 68px;
    width: 68px;
    object-fit: contain;
  }
`;
export const SuccessIconBoxSo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  img {
    height: 60px;
    width: 60px;
    object-fit: contain;
  }
`;
export const InnerSectionSuccess = styled.div`
  width: 100%;
  text-align: center;
  width: 300px;
`;

export const Cardcontainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  gap: 1.5rem;
`;
export const IconCard = styled.div`
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : `1px solid ${colorProvider.navBackground}`};
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
  flex: 1;
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 1.2rem;
  display: flex;
`;
export const CardFirst = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  /* color: ${(props) =>
    props.themeColor ? colorProvider.white : colorProvider.blueColor}; */

  .total-title {
    font-size: 1rem;
    color: ${(props) =>
      props.themeColor ? colorProvider.whiteTwo : colorProvider.comLink};
    font-weight: ${fontWeight.five};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .boldDesc {
    font-size: 1.7rem;
    font-weight: 700;
    color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.blackOne};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const IconBox = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 5px;
  .img-cont {
    padding: 10px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const SubsCard = styled.div`
  width: 97%;
  display: ${commonStyles.ds_fx};
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 1.3rem;
  overflow-x: auto;
  /* margin-left: 2rem; */
  /* max-width: 1380px; */
  position: absolute;
  min-height: 429px;
  padding: 40px 0 0 0;
  margin-top: 3.5rem;

  @media (max-width: 768px) {
    margin-left: 0rem;
    align-items: flex-start;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
export const SubsCardCourse = styled.div`
  display: flex;
  align-items: center;
  overflow-y: auto;
  min-height: 430px;
  width: 100%;
`;
export const VideoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;
export const VideoContainerSo = styled.div`
  width: 100%;
  min-width: 700px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) =>
    props.themeColor ? colorProvider.blackFive : colorProvider.white};
  padding: 1.2rem;
  position: relative;
  gap: 20px;
`;
export const BannerPlay = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  position: relative;

  img {
    height: 450px;
    width: 800px;
    object-fit: contain;
  }

  .play-butttonon {
    position: absolute;
    /* top: 50%; */
    height: 65px;
    width: 65px;
    object-fit: contain;
    /* margin-top: 1.5rem; */
    /* margin-left: -4rem; */
    cursor: pointer;
  }
`;

export const ParentCardCategory = styled.div`
  width: 420px;
  margin-right: 1rem;
  /* background-color: #f0f0f0; */
  /* padding: 20px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${commonStyles.ds_fx};
  flex-direction: ${commonStyles.fd_col};
  justify-content: ${commonStyles.jc_fs};
  align-items: ${commonStyles.jc_fs};
  box-shadow: 0px 2px 4px -1px #0000000f, 0px 4px 6px -1px #0000001a;
  border: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackFive}`
      : commonStyles.course_bor};
  /* border: 2px solid red; */
  border-radius: ${commonStyles.sec_br};
  transition: transform 0.3s linear;
  position: relative;
  :hover {
    transform: scale(1.2);
    z-index: 2;
    /* margin-left: ${(props) => (props.index === 0 ? "2.7rem" : "0")}; */
  }
  &:hover ${TrailerRow} {
    opacity: 1; // Make the trailer row fully opaque
    visibility: visible; // Make the trailer row visible
    right: 0;
    top: 0px;
  }
  &:hover ${CourseDescriptionTwo} {
    opacity: 1; // Make the trailer row fully opaque
    display: -webkit-box; // Uses the webkit line clamp feature
    margin-left: 0px;
    margin-top: 0px;
    height: auto;
  }
  &:hover ${CourseTitle} {
    color: ${(props) =>
      props.themeColor ? colorProvider.navLink : colorProvider.navLink};
  }
`;

export const CardImageTwoCourse = styled.div`
  width: 420px;
  display: ${commonStyles.ds_fx};
  justify-content: ${commonStyles.jc_c};
  flex-direction: ${commonStyles.fd_col};
  align-items: ${commonStyles.jc_c};
  /* overflow: hidden; */

  img {
    height: 135px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    /* object-fit: ${commonStyles.ob_con}; */
    object-fit: cover;
    transition: transform 0.3s linear;
  }

  .video-class-element {
    height: 170px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }
`;

export const GreenContinue = styled.div`
  color: #ffffff;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: #17b26a;
  border-radius: 8px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.85rem 1rem 0.85rem 1.3rem;
  gap: 0.5rem;
  margin: 2rem 0 1rem 0;
  cursor: pointer;

  @media (max-width: 769px) {
    margin-top: 1rem;
  }
`;
export const FilterIconsContainer = styled.div`
  display: flex;

  gap: 8px;
`;
export const FilterIconBox = styled.div`
  cursor: pointer;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }
  i {
    color: ${(props) => (props.themeColor ? "#ffffff" : "#0C111D")};
  }
`;
export const FilterIconBoxAudit = styled.div`
  height: 38px;
  width: 38px;
  cursor: pointer;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
export const FilterRow = styled.div`
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.7rem;

  /* @media (max-width: 768px) {
    width: 20%;
  } */
`;
export const FilterRowBox = styled.div`
  width: 950px;
  display: flex;
  justify-content: ${(props) => (props.isAlign ? "flex-start" : "end")};
  align-items: center;
  overflow-x: scroll;
  gap: 10px;
`;
export const FilterBoxFilter = styled.div`
  display: flex;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  align-items: center;
  border: ${(props) =>
    props.themeColor
      ? "1px solid #333741"
      : props.isFilterOpen
      ? "1px solid #FBA976"
      : "1px solid #d7dae0"};
  box-shadow: ${(props) =>
    props.isFilterOpen ? "0px 0px 0px 2px #FEE8D6" : ""};
  border-radius: 8px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#464c5e")};
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
  }

  .cross-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    padding: 16px;
    img {
      margin: 0;
    }
    .text-display {
      display: none;
    }
  }
`;
export const FilterBoxResp = styled.div`
  display: none;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  align-items: center;
  border: ${(props) =>
    props.themeColor
      ? "1px solid #333741"
      : props.isFilterOpen
      ? "1px solid #FBA976"
      : "1px solid #d7dae0"};
  box-shadow: ${(props) =>
    props.isFilterOpen ? "0px 0px 0px 2px #FEE8D6" : ""};
  border-radius: 4px;
  padding: 10px;
  color: ${(props) => (props.themeColor ? "#CECFD2" : "#464c5e")};
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  text-transform: capitalize;

  .text-display {
  }

  .cross-icon {
    height: 10px;
    width: 10px;
    margin-left: 10px;
  }
  img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    margin-right: 8px;
  }
  @media (max-width: 769px) {
    img {
      margin: 0;
    }
    display: flex;
  }
`;
export const SearchFilterRowReport = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  gap: 1rem;
  position: relative;
`;

export const TitleRowReport = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border-bottom: ${(props) =>
    props.themeColor
      ? `1px solid ${colorProvider.blackTwo}`
      : commonStyles.com_b};
  padding-bottom: 1rem;

  @media (max-width: 769px) {
    display: none;
  }
`;
export const CreateButtonResp = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
  @media (min-width: 769px) {
    display: none;
  }
`;
export const TableBoxReport = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* margin-top: 1rem; */
  text-transform: capitalize;

  ::-webkit-scrollbar {
    width: 6px !important;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    /* Color of the thumb */
    border-radius: 5px !important;
    /* Rounded corners for the thumb */
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner {
    height: 21px !important;
    width: 21px !important;

    /* background: ${(props) =>
      props.themeColor
        ? `url(${darkCheckboxBaseIcon}) no-repeat center center !important`
        : `url(${checkboxBaseIcon}) no-repeat center center !important`}; */
    background-size: contain !important;
    /* background-color: transparent !important; */
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border: ${(props) =>
      props.themeColor ? "1px solid #333741" : "1px solid #B3B9C6"} !important;
    border-radius: 6px;
  }
  td.ant-table-cell.ant-table-cell-scrollbar {
    background-color: ${(props) =>
      props.themeColor ? "#1F242F" : "#FFFFFF"} !important;
    box-shadow: none !important;
  }
  .ant-table-thead .ant-checkbox .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "" : "21px !important")};
    width: ${(props) => (props.checkCon ? "" : "21px !important")};
    background-color: transparent !important;
    background: ${(props) =>
      props.checkCon
        ? ""
        : props.themeColor
        ? `url(${checkbox_minus_dark}) no-repeat center center !important`
        : `url(${checkbox_minus_light}) no-repeat center center !important`};
    background-size: contain !important;
    border-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    height: ${(props) => (props.checkCon ? "22px !important" : "")};
    width: ${(props) => (props.checkCon ? "22px !important" : "")};
    margin-left: 6.2px !important;
    margin-top: 0.8px !important;
    transform: rotate(0deg) scale(1) translate(-50%, -50%) !important;
    background: ${(props) =>
      props.checkCon
        ? props.themeColor
          ? `url(${darkcheckIcon}) no-repeat center center !important`
          : `url(${checkboxIcon}) no-repeat center center !important`
        : ""};
  }

  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }
  &&& :where(.css-p7e5j5).ant-table-wrapper .ant-table-pagination-right {
    background-color: ${(props) =>
      props.themeColor ? "#0C111D" : "#FFFFFF"} !important;
    border-top: ${(props) =>
      props.themeColor ? "1px solid #1F242F" : "1px solid #D7DAE0"};
    border-radius: 0 0 8px 8px !important;
  }

  .border-border:where(.css-dev-only-do-not-override-p7e5j5).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  /* update */
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox
    .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-dev-only-do-not-override-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }
  /* @media (max-width: 769px) {
    display: none;
  } */
  &&& :where(.css-p7e5j5).ant-checkbox .ant-checkbox-inner {
    border-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#333741 !important"
        : "#b3b9c6 !important"};
    border-width: 1px !important;
    background-color: ${(props) =>
      props.checkCon
        ? "transparent !important"
        : props.themeColor
        ? "#0C111D !important"
        : "#fff !important"};
    border-radius: 6px;
    height: 21px !important;
    width: 21px !important;
  }
  &&&
    :where(.css-p7e5j5).ant-checkbox-wrapper:not(
      .ant-checkbox-wrapper-disabled
    ):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: none !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    /* Adjust the width as needed */
    /* background-color: black; */
  }

  /* Set the styles for the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners for the thumb */
  }
  @media (max-width: 769px) {
    display: none;
  }
`;
export const TableCardReport = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1rem 1.6rem;
  margin-top: 1rem;
  border-radius: 8px;
  background-color: ${(props) => (props.themeColor ? "#0C111D" : "#ffffff")};
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  @media (max-width: 769px) {
    display: flex;
  }
  @media (max-width: 520px) {
    padding: 1rem;
  }
`;

export const TitleRowInnerFirstReport = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  align-items: center;
`;
